//Fonts
@font-face {
  font-family: 'Miratrix-Normal';
  font-style: normal;
  src: local('Miratrix-Normal'),
       url('../fonts/Miratrix-Normal.woff2') format('woff2'),
       url('../fonts/Miratrix-Normal.woff') format('woff');
}
@font-face {
  font-family: 'SourceCodePro-Regular';
  font-style: normal;
  src: local('SourceCodePro-Regular'),
       url('../fonts/SourceCodePro-Regular.woff2') format('woff2'),
       url('../fonts/SourceCodePro-Regular.woff') format('woff');
}

// @font-face {
//   font-family: 'Michroma-Regular';
//   font-style: normal;
//   src: url('../fonts/Michroma-Regular.woff2') format('woff2');
// }
// @font-face {
//   font-family: 'AzeretMono-Regular';
//   font-style: normal;
//   src: url('../fonts/AzeretMono-Regular.woff2') format('woff2');
// }
$font-main: Miratrix-Normal;
$font-p: SourceCodePro-Regular;

  //Colors Theme one
  $black: #151C22;
  $white: #ffffff;
  $blue: #1400F5;
  $gray: #DEE3FF;
  $red: #FF004D;
  $pink: #FDC2D1;
  $dark-gray: gray;
  $modal-bg-color: rgb(39, 37, 37);

  //Colors Theme two
  $violet-light: #D941FF;
  $violet-dark: #310FB9;

  //Colors Theme three
  $red-dark: #E7006F;
  $azure: #119DC9;

  //Colors Theme four
  $orange: #FF0000;
  $azure-light: #61A8BE;

  //Colors Theme five
  $acid: #52FF00;