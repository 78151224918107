@import "scss/Variable";
@import "scss/Mixins.scss";
@import "./components/theme/Dark.scss";

html, body {
    max-width: 100%;
    overflow-x: hidden;
}
html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
*:not(p){
    font-family: $font-main;
}
p{
    font-family: $font-p;
}
body{
    margin: 0;
    padding: 0;
    min-width: 320px;
    background: $black;
}


.cases-block{
    background: $red;
    min-height: 86px;
    display: flex;
    align-items: center;
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
    & .overlay{
        --gradient-color: transparent !important;
    }
    & span{
        margin-right: 35px;
    }
}

.setting-site{
    display: block;
    gap: 10px;
    width: 42px;
    height: 40px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    left: -74px;
    transition: height .3s ease-in-out;
    z-index: 6;
    &:hover{
        height: 265px;
    }
    svg{
        display: block;
        margin-bottom: 5px;
        cursor: pointer;
    }
}

.phone-corp {
    position: absolute;
    top: 106px;
    right: 1px;
}
.phone-link {
    position: relative;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 29px;
    line-height: 36px;
    text-decoration: none;
    color: $red;
    z-index: 5;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    cursor: pointer;
    &:hover, &:focus-visible {
        color: $black;
        background-color: $red;
    }
}

.telegram {
    width: 45px;
    height: 45px;
}

.telegram-link {
    display: inline-block;
    width: 100%;
    height: 100%;
    outline: none;
    background-image: url('./image/telegram-default.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-image .3s ease-in-out;
    cursor: pointer;
    &:hover {
        background-image: url('../src/image/telegram-hover.svg');
    }
}

.header__red-emptyCross, .header__red-fillCross, .header__blue-emptyCross, .header__blue-fillCross, .header__blue-bottomEmptyCross {
    transition: stroke .3s ease-in-out, fill .3s ease-in-out;
}

.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: url('../src/image/scroll-to-top.svg') no-repeat;
    z-index: 10;
    cursor: pointer;
    transition: background .3s ease-in-out;
    &:focus-visible, &:hover {
        background: url('../src/image/scroll-to-top-hover.svg') no-repeat;
    }
}
