@import "../../scss/Variable";

.PhoneInputInput {
    border: none;
    outline: none;
    color: $white;
    background-color: $black;
}

.PhoneInputInput:focus::-webkit-input-placeholder {
    color: transparent;
  }