@import "../../scss/Variable";
@import "../../scss/Mixins.scss";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    &.active {
        opacity: 1;
        pointer-events: all;   
    } 
}

.modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    min-height: 200px;
    padding: 40px 30px;
    border-radius: 10px;
    color: $white;
    background-color: $black;
}

.modal__title {
    margin-bottom: 20px;
    width: 70%;
    text-align: center;
}

.modal__input-wrapper {
    margin-bottom: 25px;
}

.modal__input {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 20px;
    min-width: 440px;
    border: 2px solid $white;
    border-radius: 10px;
    outline: none;
    color: $white;
    caret-color: $white;
    background-color: $black;
    transition: color .3s ease-in-out;
}

.modal__input::placeholder {
    color: $dark-gray;
}

.modal__input:focus::placeholder {
    color: transparent;
  }

.modal__input:not(:last-child) {
    margin-bottom: 15px;
}

.modal__button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal__button, .modal__button-cancel {
    padding: 9px 52px;
    border: 2px solid $red;
    border-radius: 52px;
    outline: none;
    color: $red;
    background-color: transparent;
    cursor: pointer;
    transition-property: color, background-color;
    transition: .3s ease-in-out;
    &:disabled {
        border-color: $dark-gray;
        color: $dark-gray;
        &:hover {
            border-color: $dark-gray;
            color: $dark-gray;
            background-color: $black;
        }
    }
}

.modal__button:hover, .modal__button-cancel:hover {
    color: $black;
    background-color: $red;
}