@import "../../scss/Mixins";
*{
  margin: 0;
  padding: 0;
}
.header{
  min-height: 885px;
  height: 37vh;
  & .container{
    height: 100%;
  }
  &__block{
    position: relative;
    height: 100%;
    background:
            url('../../image/table-l.svg') 0 0 no-repeat,
            url('../../image/table-r.svg') 100% 100% no-repeat;
    transition: background .3s ease-in-out;
    &__top{
      position: relative;
      text-align: center;
      top: 35%;
      z-index: 5;
      & h1{
        font-size: 125.068px;
        line-height: 150px;
        text-transform: uppercase;
        color: $white;
      }
    }
  }
}


// Absolute Element

.header__red-emptyCross, .header__red-fillCross, .header__blue-emptyCross, .header__blue-fillCross, .header__blue-bottomEmptyCross {
  position: absolute;
}

.header__red-emptyCross path, 
.header__red-fillCross path,
.header__blue-emptyCross path, 
.header__blue-fillCross path, 
.header__blue-bottomEmptyCross path {
    transition: fill .3s ease-in-out;
}

.star-elips-head .star-stop1, .star-elips-head .star-stop2 {
  transition: stop-color .3s ease-in-out;
}

.setting-site-icon path {
  transition: stroke .3s ease-in-out, fill .3s ease-in-out;
}

.header__red-emptyCross {
  top: 13%;
  left: 2%;
  z-index: 4;
}

.header__red-fillCross {
  top: 13%;
  left: 8%;
  z-index: 4;
}

.header__blue-emptyCross {
  top: 20.1%;
  right: 2.4%;
  z-index: 4;
  path { 
    fill: $blue;
  }
}

.header__blue-fillCross {
  top: 27%;
  right: 2.4%;
  z-index: 4;
  path {
    fill: $blue;
  }
}

.header__blue-bottomEmptyCross {
  top: 63.5%;
  right: 2.4%;
  z-index: 4;
  path {
    fill: $blue;
  }
}





.cross_one, .cross_two, .cross_three, .cross_four, .blur-circle-one, .blur-circle-two, .blur-circle-three, .btn-header, .star-elips-head, .cross_logo{
  position: absolute;
}

.cross_one, .cross_two{
  top: 72px;
}
.cross_one{
  left: 27px;
}
.cross_two{
  left: 120px;
}

.cross_three, .cross_four{
  bottom: 45px;
}
.cross_three{
  right: 113px;
}
.cross_four {
  right: 22px;
}

.star-elips-head{
  top: 53%;
  left: 48.3%;
  z-index: 5;
}

.blur-circle-one{
  top: 26%;
  left: 24%;
  z-index: 4;
}
.blur-circle-two{
  top: 1%;
  right: 20%;
  z-index: 3;
}
.blur-circle-three{
  top: 8%;
  right: 0;
  z-index: 3;
}
.btn-header{
  right: 0;
  top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  z-index: 5;
}

.cross_logo{
  top: 37.7%;
  left: 160px;
  z-index: 6;
}
