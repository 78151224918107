@import "../../scss/Mixins";

.portfolio {
    &__block {
        position: relative;
        padding: 90px 0;
        background: url('../../image/table-portfolio.svg') 104% 100% no-repeat;
    }
}

.top-crossEmpty {
    position: absolute;
    top: 11.2%;
    right: 7.5%;
}
.top-crossFill {
    position: absolute;
    top: 16.5%;
    right: 7.5%;
}
.bottom-crossEmpty {
    position: absolute;
    top: 37%;
    right: 19%;
    z-index: 1;
}

// Card

.card {
    position: relative;
    width: 65.5%;
    background: #151C22;
    border: 3px solid #FDC2D1;
    text-align: left;
    z-index: 2;
    &__head {
        padding: 10px 26px;
        border: 3px solid #FDC2D1;
    } &__body {
        padding: 42px 25px 53px;
        border: 3px solid #FDC2D1;
    } &__image img {
        display: block;
    }
    .opencart-bg {
        position: absolute;
        top: -8.7%;
        left: 76.5%;
    }
    .vue-bg {
        position: absolute;
        top: -8%;
        left: 76.5%;
    }
    .card-title {
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        text-transform: uppercase;
        color: #FDC2D1;
    }
    .card-title-2 {
        width: 54%;
        font-size: 26px;
        line-height: 31px;
    }
    .card-descr {
        margin-bottom: 60px;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: $white;
    } 
    .card-link {
        font-family: 'Source Code Pro';
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        text-decoration-line: underline;
        color: $red;
        transition: color .3s ease-in-out, background-color .3s ease-in-out;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $red;
        }
    }
}

.card-top {
    margin-bottom: 116px;
}

.descr-link {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: $red;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    &:hover, &:focus-visible {
        color: $white;
        background-color: $red;
    }
}

.img-top {
    width: 100%;
    min-height: 363px;
    background: url('../../image/portfolioCard1.png') center no-repeat;
    background-size: cover;
}
.img-bottom {
    width: 100%;
    min-height: 363px;
    background: url('../../image/portfolioCard2.png') center no-repeat;
    background-size: cover;
}

.red-blur-one, .red-blur-two, .red-blur-three, .blue-blur, .black-blur-portfolio, .black-blur-two {
    position: absolute;
}

.portfolioBlue-blur-circle{
    background: $blue;
    opacity: 0.3;
    filter: blur(60.5px);
    width: 645px;
    height: 645px;
    border-radius: 50%;
  }
  .blue-blur {
    top: 30%;
    right: 0;
    z-index: 0;
  }

  .red-blur-portfolio {
    width: 443px;
    height: 443px;
    border-radius: 50%;
    background: $red;
    opacity: 0.3;
    filter: blur(60.5px);
  }
  .red-blur-one {
    top: 7%;
    right: 17%;
    z-index: 1;
  }

  .red-blur-two {
    bottom: 0;
    right: 24%;
    z-index: 0;
  }

  .red-blur-portfolioSmall {
    width: 345px;
    height: 345px;
    border-radius: 50%;
    background: $red;
    opacity: 0.3;
    filter: blur(60.5px);
  }
  .red-blur-three {
    top: 41%;
    left: 0;
    z-index: 0;
  }

  .black-blur-portfolio {
    width: 624px;
    height: 624px;
    border-radius: 50%;
    background: $black;
    opacity: 0.3;
    filter: blur(48.5px);
  }
  .black-blur-portfolio {
    top: 0;
    left: 40%;
    z-index: 0;
  }

  .black-blur-portfolioSmall {
    width: 402px;
    height: 402px;
    border-radius: 50%;
    background: $black;
    opacity: 0.3;
    filter: blur(48.5px);
  }
  .black-blur-two {
    top: 33%;
    left: 46%;
    z-index: 1;
  }

@media(max-width: 1200px) {
    .portfolio {
        &__block {
            background-size: contain;
            background-position: 117% 100%;
        }
    }

    .top-crossEmpty {
        position: absolute;
        top: 11.2%;
        right: 3.5%;
    }
    .top-crossFill {
        position: absolute;
        top: 16%;
        right: 3.5%;
    }
    .bottom-crossEmpty {
        position: absolute;
        top: 35.5%;
        right: 13.5%;
        z-index: 1;
    }
     
    .card {
        &__body {
            padding: 27px 18px 29px;
        }
        .opencart-bg {
            left: 65.5%;
        }
        .vue-bg {
            left: 65.5%;
        }
        .card-title {
            font-size: 34px;
            line-height: 40px;
        }
        .card-title-2 {
            width: 60%;
            font-size: 24px;
            line-height: 28px;
        }
        .card-descr {
            margin-bottom: 40px;
            font-size: 17px;
            line-height: 22px;
        } 
        .card-link {
            font-size: 26px;
            line-height: 30px;
        }
    }
}

@media(max-width: 768px) {
    .portfolio__block {
        background: none;
      }

    .card {
        width: 100%;
    }
}

// @media(max-width: 640px) {
//     .portfolio__block {
//       background: none;
//     }
// }

@media(max-width: 576px) {

    .portfolio__block {
        padding: 70px 0;
    }

    .card .card-title {
        font-size: 26px;
    }

    .card .card-descr {
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 16px;
    }

    .card .card-link {
        font-size: 20px;
    }

    .card .opencart-bg svg, .card .vue-bg svg {
        width: 124px;
        height: 82px;
    }

    .img-top, .img-bottom {
        min-height: 152px;
    }

    .card .card-title-2 {
        width: 100%;
    }

    .descr-link {
        font-size: 16px;
    }

    .card-top {
        margin-bottom: 75px;
    }
}