@import "../../scss/Mixins";

.footer{
  background: $blue;
  & .footer__main{
    min-height: 337px;
  }
  & .footer__main__header{
    min-height: 71px;
    display: flex;
    align-items: center;
    font-size: 60px;
    line-height: 72px;
    text-transform: uppercase;
    & a{
      color:$pink;
      text-decoration: none;
      transition: 0.3s ease-in-out;
      &:hover{
        color:$red;
      }
    }
    & span{
      color:$red;
    }
  }
  & .footer__main__social{
    min-height: 266px;
    display: flex;
    justify-content: end;
    align-items: center;
    & > svg{
      margin-left:33px;
      cursor: pointer;
    }
  }
}

.copy-link {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  font-family: $font-p;
  font-size: 18px;
  color:$white;
  // & p {
  //   transition: color .3s ease-in-out;
  // }
  // &:hover p {
  //   color: $red;
  // }
}

.transparentEllipse {
  position: relative;
  width: 191px;
  height: 149px;
  background: url('../../image/mail-icon-initial.svg') no-repeat center;
  transition: background .3s ease-in-out;
  cursor: pointer;
  &:focus-visible, &:hover {
    background: url('../../image/mail-icon.svg') no-repeat center;
  }
}
.redStar path {
  transition: fill .3s ease-in-out;
}
.redStar:hover path {
  fill: #1400F5;
}
