@import "../../scss/Mixins";
.front-end-block{
  position: relative;
  .heading-front-cursor {
    position: absolute;
    top: -12%;
    left: 24.8%;
  }
  &__main{
    position: relative;
    padding-bottom: 100px;
    &__content{
      margin-top: 40px;
      margin-bottom: 30px;
      padding: 0 150px;
      min-height: 125px;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color:$white;
    }
    &__tech{
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 60px;
      grid-column-gap: 114px;
      justify-items: center;
      align-items: center;
    }
    .saw-decoration {
      position: absolute;
      top: 70.5%;
      left: -19%;
      z-index: 1;
    }
    .ellipse-decoration {
      position: absolute;
      top: 60.5%;
      left: 69.5%;
      z-index: -1;
    }
    .frontend-blue-topCross {
      position: absolute;
      top: 65%;
      left: 5%;
      z-index: -1;
      path {
        fill: $blue;
      }
    }
    .frontend-blue-bottomCross {
      position: absolute;
      top: 82%;
      left: 5%;
      path {
        fill: $blue;
      }
    }
    .icon {
      position: relative;
      width: 294px;
      height: 294px;
    }
    .html-bg {
      background: url('../../image/html5-1.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/html-hover.svg') no-repeat center;
      }
    }

    .css-bg {
      background: url('../../image/css.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/css-hover.svg') no-repeat center;
      }
    }

    .js-bg {
      background: url('../../image/js.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/js-hover.svg') no-repeat center;
      }
    }

    .scss-bg {
      background: url('../../image/sass.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/sass-hover.svg') no-repeat center;
      }
    }

    .react-bg {
      background: url('../../image/react.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/react-hover.svg') no-repeat center;
      }
    }

    .vue-bg {
      background: url('../../image/vue.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/vue-hover.svg') no-repeat center;
      }
    }
  }
}
.heading-tech{
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue;
  position: relative;
  overflow: hidden;
  transition: background .3s ease-in-out;
  .container{
    position: relative;
    text-align: center;
  }
  h2{
    font-size: 60px;
    line-height: 72px;
    color: $white;
    text-transform: uppercase;
    position: relative;
    z-index: 5;
  }
}
.right-red-blur, .left-red-blur {
  position: relative;
  & .blur-for-heading{
    width: 371px;
    height: 371px;
    position: absolute;
    background: #FF004D;
    transition: background .3s ease-in-out;
    filter: blur(121px);
    z-index: 4;
    top: -150px;
  }
}
.right-red-blur{
  & .blur-for-heading {
    right: 100px;
  }
}
.left-red-blur{
  & .blur-for-heading {
    left: 100px;
  }
}

//back-end

.back-end-block{
  position: relative;
  background: url('../../image/cms-line.svg') 100% 68% no-repeat,
              url('../../image/cms-line.svg') 0% 68% no-repeat;
  background-size: 42%;
  .heading-back-cursor {
    position: absolute;
    top: -6.5%;
    right: 27%;
  }
  &__subtitle{
    position: absolute;
    right: 45%;
    bottom: 33%;
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    color: #1400F5;
  }
  &__main{
    position: relative;
    padding-bottom: 100px;
    &__content{
      margin-top: 40px;
      margin-bottom: 62px;
      padding: 0 150px;
      min-height: 125px;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color:$white;
    }
    &__tech{
      position: relative;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 25px;
      grid-column-gap: 114px;
      justify-items: center;
      align-items: center;
    }
    .saw-decoration-back {
      position: absolute;
      bottom: 12.5%;
      right: -7.5%;
      z-index: 1;
      transform: rotate(90deg);
    }
    .ellipse-decoration-back {
      position: absolute;
      right: 0%;
      bottom: 0%;
      z-index: -1;
    }
    .back-blue-topCross {
      position: absolute;
      top: -5.5%;
      right: 4.5%;
      path {
        fill: $blue;
      }
    }
    .back-blue-bottomCross {
      position: absolute;
      top: 11.2%;
      right: 4.5%;
      path {
        fill: $blue;
      }
    }
    .icon {
      position: relative;
      width: 294px;
      height: 294px;
    }

    .node-bg {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      background: url('../../image/node.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/node-hover.svg') no-repeat center;
      }
    }

    .express-bg {
      grid-column: 3 / 5;
      grid-row: 1 / 2;
      background: url('../../image/express.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/express-hover.svg') no-repeat center;
      }
    }

    .next-bg {
      grid-column: 5 / 7;
      grid-row: 1 / 2;
      background: url('../../image/next.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/next-hover.svg') no-repeat center;
      }
    }

    .php-bg {
      grid-column: 2 / 4;
      grid-row: 2 / 3;
      background: url('../../image/php.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/php-hover.svg') no-repeat center;
      }
    }

    .laravel-bg {
      grid-column: 4 / 6;
      grid-row: 2 / 3;
      background: url('../../image/laravel.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/laravel-hover.svg') no-repeat center;
      }
    }

    .wordpress-bg {
      grid-column: 2 / 4;
      grid-row: 3 / 3;
      margin-top: 50px;
      background: url('../../image/wordpress.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/wordpress-hover.svg') no-repeat center;
      }
    }

    .opencart-bg {
      grid-column: 4 / 6;
      grid-row: 3 / 3;
      margin-top: 50px;
      background: url('../../image/opencart.svg') no-repeat center;
      transition: background .3s ease-in-out;
      &:focus-visible, &:hover {
        background: url('../../image/opencart-hover.svg') no-repeat center;
      }
    }
  }
}
