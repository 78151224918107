@import "Variable";

.container{
  padding: 0 15px;
  max-width: 1560px;
  margin: 0 auto;
}

//Buttons
.button-red{
  background: transparent;
  color: $red;
  outline: none;
  border:2px solid $red;
  border-radius: 52px;
  font-size: 28px;
  line-height: 30px;
  padding: 6px 57px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover, &:focus-visible{
    background: $red;
    color: $black;
  }
}

//Stars
.redStar{
  & path:first-child, circle{
    fill: $red;
  }
}

.transparentEllipse{
  & path:first-child, circle{
    fill: transparent;
  }
}

//Blures
.red-blur-circle{
  background: $blue;
  opacity: 0.3;
  filter: blur(121px);
  width: 287px;
  height: 287px;
  border-radius: 50%;
}
.gray-blur-elipse{
  background: $black;
  filter: blur(97px);
  transform: rotate(-16.76deg);
  width: 1260px;
  height: 900px;
  border-radius: 40%;
}
.black-blur-circle{
  background: $black;
  filter: blur(48.5px);
  width: 624px;
  height: 624px;
  border-radius: 40%;
}

// media

@media(max-width: 1200px) {

  .header__red-emptyCross {
    top: 12%;
    left: 4%;
  }

  .header__red-fillCross {
    top: 12%;
    left: 15%;
  }

  .button-red {
    padding: 5px 36px;
    font-size: 20px;
    line-height: 26px;
  }

  .phone-link {
    font-size: 22px;
    line-height: 26px;
  }

  .telegram {
    width: 40px;
    height: 40px;
  }

  .setting-site {
    top: 2px;
  }

  .header__blue-emptyCross, .header__blue-fillCross, .header__blue-bottomEmptyCross {
    right: 3.4%;
  }

  .header__block__top h1 {
    font-size: 90.068px;
  }

  // front

  .front-end-block .heading-front-cursor {
    top: -7%;
    left: 20.8%;
  }
  
  .heading-tech h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .front-end-block__main__content {
    padding: 0;
    width: 100%;
    font-size: 18px;
  }

  .front-end-block__main__tech {
    grid-template-columns: repeat(2, 1fr);
  }

  // back 

  .back-end-block {
    background: none;
    // background: url('../image/cms-line.svg') 100% 74% no-repeat, 
    //             url('../image/cms-line.svg') 0% 74% no-repeat;
    //             background-size: 42%;
    &__subtitle {
      right: 43%;
      bottom: 25%;
    }
    &__main {
      &__content {
        padding: 0;
        width: 100%;
        font-size: 18px;
      }
      &__tech {
        grid-template-columns: repeat(2, 1fr);
      }
      .node-bg {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    
      .express-bg {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    
      .next-bg {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
    
      .php-bg {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
    
      .laravel-bg {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        margin-bottom: 50px;
      }
    
      .wordpress-bg {
        grid-column: 1 / 2;
        grid-row: 4 / 4;
      }
    
      .opencart-bg {
        grid-column: 2 / 3;
        grid-row: 4 / 4;
      }
    }
  }

  //footer 

  .footer {
   & .footer__main {
      min-height: 300px;
    }
    & .footer__main__header {
      justify-content: center;
      font-size: 40px;
      line-height: 60px;
    }
    & .footer__main__social {
      min-height: 200px;
    }
  }
}

@media(max-width: 768px) {

  .header__block__top h1 {
    font-size: 75.068px;
  }

  .header__red-emptyCross {
    top: 18%;
  }

  .header__red-fillCross {
    top: 18%;
  }

  .front-end-block .heading-front-cursor {
    top: -3%;
    left: 11.8%;
  }

  .front-end-block__main__tech {
    grid-template-columns: repeat(1, 1fr);
  }

  .back-end-block {
    background: none;
    // background: url('../image/cms-line.svg') 110% 70.5% no-repeat, 
    //             url('../image/cms-line.svg') -10% 70.5% no-repeat;
    // background-size: 42%;
  }

  .back-end-block .heading-back-cursor {
    top: -4%;
    right: 14%;
  }

  .back-end-block__main__tech {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 60px;
  }

  .back-end-block__main .node-bg {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }

  .back-end-block__main .express-bg {
    grid-column: 1 / 1;
    grid-row: 2 / 3;
  }

  .back-end-block__main .next-bg {
    grid-column: 1 / 1;
    grid-row: 3 / 4;
  }

  .back-end-block__main .php-bg {
    grid-column: 1 / 1;
    grid-row: 4 / 5;
  }

  .back-end-block__main .laravel-bg {
    grid-column: 1 / 1;
    grid-row: 5 / 6;
  }

  .back-end-block__main .wordpress-bg {
    grid-column: 1 / 1;
    grid-row: 6 / 7;
  }

  .back-end-block__main .opencart-bg {
    grid-column: 1 / 1;
    grid-row: 7 / 8;
  }

  .back-end-block__subtitle {
    // display: none;
    right: 42%;
    bottom: 29.5%;
  }

  .back-end-block__main .wordpress-bg {
    margin-top: 0;
  }

  .footer .footer__main__social {
    flex-direction: column;
    padding: 40px 0;
  }

  .transparentEllipse {
    margin-bottom: 30px;
  }

  .footer .footer__main__social > svg {
    margin-left: 0;
  }

  .footer .footer__main__social > svg:not(:last-child) {
    margin-bottom: 30px;
  }

  .footer__links {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .cases-block {
    min-height: 50px;
    font-size: 38px;
  }
}

@media(max-width: 576px) {

  .header {
    min-height: 627px;
  }

  .header__block__top {
    z-index: 4;
  }

  .modal__content {
    padding: 15px;
    max-width: 290px;
  }

  .modal__title {
    width: 100%;
    font-size: 17px;
  }

  .modal__input {
    padding: 7px 15px;
    min-width: 270px;
  }

  .modal__button, .modal__button-cancel {
    padding: 6px 26px;
  }

  .modal__input-wrapper div {
    font-size: 16px;
  }

  .header__block {
    background: none;
  }

  .btn-header {
    top: 20px;
    gap: 20px;
  }

  .setting-site {
    top: 60px;
    left: 1px;
    width: 35px;
    height: 35px;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .button-red {
    padding: 6px 50px;
  }

  .phone-corp {
    top: 84px;
  }

  .scroll-to-top {
    bottom: 30px;
    right: 30px;
  }

  .header__block__top h1 {
    font-size: 60.068px;
    line-height: 75px;
  }

  .cross-size-mob svg {
    width: 25px;
    height: 25px;
  }

  .header__red-emptyCross {
    top: 29.5%;
    left: 3%;
  }

  .header__red-fillCross {
    top: 29.5%;
    left: 17%; 
  }

  .header__blue-emptyCross {
    top: 23%;
    right: 7%;
  }

  .header__blue-fillCross {
    top: 29%;
    right: 7%;
  }

  .header__blue-bottomEmptyCross {
    right: 7%;
    top: 77%;
  }

  .star-elips-head {
    top: 59%;
    width: 85px;
    height: 52px;
  }

  .red-blur-circle{
    width: 200px;
    height: 200px;
    background: $red;
  }

  .black-blur-circle{
    background: $black;
    width: 400px;
    height: 400px;
  }
  .blur-circle-three{
    top: -29%;
    right: -20%;
  }

  .gray-blur-elipse{
    background: $black;
    width: 500px;
    height: 500px;
  }
  .blur-circle-two {
    top: 24%;
    right: 0%;
  }

  .heading-tech {
    height: 60px;
    & h2 {
      font-size: 38px;
      line-height: 50px;
    }
  }

  .front-end-block .heading-front-cursor {
    display: none;
  }

  .front-end-block__main__content {
    margin-top: 25px;
    font-size: 14px;
  }

  .back-end-block .heading-back-cursor {
    display: none;
  }

  .back-end-block__main__content {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 14px;
  }

  .back-end-block__subtitle {
    right: 31%;
    bottom: 30%;
  }

  .back-end-block__main {
    padding-bottom: 50px;
  }

  .cases-block {
    font-size: 30px;
  }

  .footer .footer__main__header {
    font-size: 26px;
    line-height: 37px;
  }

  .copy-link {
    font-size: 13px;
  }
}