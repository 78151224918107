@import "./src/scss/Mixins";
@import "./src/scss/Variable";

html[data-theme="theme-one"] {
    
}

html[data-theme="theme-two"] {
    
    .header{
        &__block{
            background:
                  url('../../image-theme-two/table-l.svg') 0 0 no-repeat,
                  url('../../image-theme-two/table-r.svg') 100% 100% no-repeat;
            transition: background .3s ease-in-out;
                  @media(max-width: 576px) {
                    background: none;
                  }
        }
    }

    .scroll-to-top {
        background: url('../../image-theme-two/scroll-to-top.svg') no-repeat;
        transition: background .3s ease-in-out;
        &:focus-visible, &:hover {
            background: url('../../image-theme-two/scroll-to-top-hover.svg') no-repeat;
        }
    }

    .setting-site-icon path {
        transition: stroke .3s ease-in-out, fill .3s ease-in-out;
    }

    .setting-site-icon path {
        stroke: $violet-light;
    }

    .setting-site-drop {
        fill: $violet-light;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path,
    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        transition: fill .3s ease-in-out;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path {
        fill: $violet-light;
    }

    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        fill: $violet-dark;
    }

    .star-elips-head .star-stop1, .star-elips-head .star-stop2 {
        transition: stop-color .3s ease-in-out;
    }

    .star-elips-head {
        .star-stop1 {
            stop-color: $violet-dark;
        }
        .star-stop2 {
            stop-color: $black;
        }
    }

    .telegram-link {
        background-image: url('../../image-theme-two/telegram.svg');
        &:hover {
            background-image: url('../../image-theme-two/telegram-hover-theme2.svg');
        }
    }

    .button-red{
        border-color: $violet-light;
        color: $violet-light;
        &:hover, &:focus-visible{
          background: $violet-light;
          color: $black;
        }
      }

    .phone-link {
        color: $violet-light;
        &:hover, &:focus-visible {
            color: $black;
            background-color: $violet-light;
        }
    }

    .modal__button, .modal__button-cancel {
        border-color: $violet-light;
        color: $violet-light;
        &:disabled {
            border-color: $dark-gray;
            color: $dark-gray;
            &:hover {
                border-color: $dark-gray;
                color: $dark-gray;
                background-color: $black;
            }
        }
    }

    .modal__button:hover, .modal__button-cancel:hover {
        color: $black;
        background-color: $violet-light;
    }

    .red-blur-circle {
        background: $violet-dark;
    }

    .heading-tech {
        background: $violet-dark;
    }

    .left-red-blur .blur-for-heading {
        background: $violet-light;
    }

    .front-end-block {
        &__main {
            .html-bg {
                background: url('../../image-theme-two/html5.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-two/html-hover.svg') no-repeat center;
                  }
            }
        
            .css-bg {
                background: url('../../image-theme-two/css3.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-two/css-hover.svg') no-repeat center;
                  }
            }

            .js-bg {
                background: url('../../image-theme-two/js.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-two/js-hover.svg') no-repeat center;
                  }
            }

            .scss-bg {
                background: url('../../image-theme-two/sass.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-two/sass-hover.svg') no-repeat center;
                  }
            }

            .react-bg {
                background: url('../../image-theme-two/react.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-two/react-hover.svg') no-repeat center;
                  }
            }

            .vue-bg {
                background: url('../../image-theme-two/vue.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-two/vue-hover.svg') no-repeat center;
                  }
            }
        }
    }

    .front-end-block__main .frontend-blue-topCross path,
    .front-end-block__main .frontend-blue-bottomCross path {
        fill: $violet-dark;
    }

    .right-red-blur .blur-for-heading {
        background: $violet-light;
    }
    .back-end-block__main {
        .node-bg {
            background: url('../../image-theme-two/node.js.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/node-hover.svg') no-repeat center;
              }
        }
        .express-bg {
            background: url('../../image-theme-two/ex.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/express-hover.svg') no-repeat center;
              }
        }
        .next-bg {
            background: url('../../image-theme-two/next.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/next-hover.svg') no-repeat center;
              }
        }
        .php-bg {
            background: url('../../image-theme-two/php.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/php-hover.svg') no-repeat center;
              }
        }
        .laravel-bg {
            background: url('../../image-theme-two/laravel.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/laravel-hover.svg') no-repeat center;
              }
        }
        .wordpress-bg {
            background: url('../../image-theme-two/wordpress.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/wordpress-hover.svg') no-repeat center;
              }
        }
        .opencart-bg {
            background: url('../../image-theme-two/opencart.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-two/opencart-hover.svg') no-repeat center;
              }
        }
    }

    .back-end-block__main .back-blue-topCross path, .back-end-block__main .back-blue-bottomCross path {
    fill: $violet-dark;
    }

    .back-end-block {
    background: url('../../image-theme-two/cms-line.svg') 100% 68% no-repeat,
                url('../../image-theme-two/cms-line.svg') 0% 68% no-repeat;
    background-size: 42%;
    @media(max-width: 1200px) {
        background: none;
      }
    }

    .back-end-block__subtitle {
    color: $violet-dark;
    }

    .cases-block {
    background: $violet-light;
    }

    .card {
        .opencart-bg {
            .stop-color1 {
                stop-color: $violet-dark;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }
    .card {
        .vue-bg {
            .stop-color1 {
                stop-color: $violet-dark;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }

    .portfolio {
        &__block {
            background: url('../../image-theme-two/table.svg') 104% 100% no-repeat;
            @media(max-width: 768px) {
                background: none;
            }
        }
    }

    .portfolioBlue-blur-circle {
        background: $violet-dark;
    }

    .red-blur-portfolio, .red-blur-portfolioSmall {
        background: $violet-light;
    }

    .top-crossEmpty path, .top-crossFill path, .bottom-crossEmpty path {
        fill: $violet-light;
    }

    .card .card-link {
        color: $violet-light;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $violet-light;
        }
    }

    .descr-link {
        color: $violet-light;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $violet-light;
        }
    }

    .footer {
        background: $violet-dark;
    }

    .footer__main__header span {
        color: $violet-light;
    }

    .transparentEllipse {
        background: url('../../image-theme-two/mail.svg') no-repeat center;
        &:focus-visible, &:hover {
            background: url('../../image-theme-two/mail-icon.svg') no-repeat center;
          }
    }

    .footer__main__header a:hover {
        color: $violet-light;
    }

    .footer-be circle {
        fill: $violet-light;
    }

    .footer-insta .insta-icon {
        fill: $pink;
    }

    .footer-insta path {
        fill: $violet-light;
    }

    .redStar:hover .insta-icon, .footer-be:hover path {
        fill: $violet-dark;
      }
}

html[data-theme="theme-three"] {
    .header{
        transition: background .3s ease-in-out;
        &__block{
          background:
                  url('../../image-theme-three/table-l.svg') 0 0 no-repeat,
                  url('../../image-theme-three/table-r.svg') 100% 100% no-repeat;
                  @media(max-width: 576px) {
                    background: none;
                  }
        }
    }

    .scroll-to-top {
        background: url('../../image-theme-three/scroll-to-top.svg') no-repeat;
        transition: background .3s ease-in-out;
        &:focus-visible, &:hover {
            background: url('../../image-theme-three/scroll-to-top-hover.svg') no-repeat;
        }
    }

    .setting-site-icon path {
        transition: stroke .3s ease-in-out, fill .3s ease-in-out;
    }

    .setting-site-icon path {
        stroke: $red-dark;
    }

    .setting-site-drop {
        fill: $red-dark;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path,
    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        transition: fill .3s ease-in-out;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path {
        fill: $red-dark;
    }

    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        fill: $azure;
    }

    .star-elips-head .star-stop1, .star-elips-head .star-stop2 {
        transition: stop-color .3s ease-in-out;
    }

    .star-elips-head {
        .star-stop1 {
            stop-color: $azure;
        }
        .star-stop2 {
            stop-color: $black;
        }
    }

    .telegram-link {
        background-image: url('../../image-theme-three/telegram.svg');
        &:hover {
            background-image: url('../../image-theme-three/telegram-hover-theme3.svg');
        }
    }

    .button-red{
        border-color: $red-dark;
        color: $red-dark;
        &:hover, &:focus-visible{
          background: $red-dark;
          color: $black;
        }
      }

    .phone-link {
        color: $red-dark;
        &:hover, &:focus-visible {
            color: $black;
            background-color: $red-dark;
        }
    }

    .modal__button, .modal__button-cancel {
        border-color: $red-dark;
        color: $red-dark;
        &:disabled {
            border-color: $dark-gray;
            color: $dark-gray;
            &:hover {
                border-color: $dark-gray;
                color: $dark-gray;
                background-color: $black;
            }
        }
    }

    .modal__button:hover, .modal__button-cancel:hover {
        color: $black;
        background-color: $red-dark;
    }

    .red-blur-circle {
        background: $azure;
    }

    .heading-tech {
        background: $azure;
    }

    .left-red-blur .blur-for-heading {
        background: $red-dark;
    }

    .front-end-block {
        &__main {
            .html-bg {
                background: url('../../image-theme-three/html5.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-three/html-hover.svg') no-repeat center;
                  }
            }
        
            .css-bg {
                background: url('../../image-theme-three/css3.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-three/css-hover.svg') no-repeat center;
                  }
            }

            .js-bg {
                background: url('../../image-theme-three/js.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-three/js-hover.svg') no-repeat center;
                  }
            }

            .scss-bg {
                background: url('../../image-theme-three/sass.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-three/sass-hover.svg') no-repeat center;
                  }
            }

            .react-bg {
                background: url('../../image-theme-three/react.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-three/react-hover.svg') no-repeat center;
                  }
            }

            .vue-bg {
                background: url('../../image-theme-three/vue.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-three/vue-hover.svg') no-repeat center;
                  }
            }
        }
    }

    .front-end-block__main .frontend-blue-topCross path,
    .front-end-block__main .frontend-blue-bottomCross path {
        fill: $azure;
    }

    .right-red-blur .blur-for-heading {
        background: $red-dark;
    }
    .back-end-block__main {
        .node-bg {
            background: url('../../image-theme-three/node.js.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/node-hover.svg') no-repeat center;
              }
        }
        .express-bg {
            background: url('../../image-theme-three/ex.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/express-hover.svg') no-repeat center;
              }
        }
        .next-bg {
            background: url('../../image-theme-three/next.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/next-hover.svg') no-repeat center;
              }
        }
        .php-bg {
            background: url('../../image-theme-three/php.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/php-hover.svg') no-repeat center;
              }
        }
        .laravel-bg {
            background: url('../../image-theme-three/laravel.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/laravel-hover.svg') no-repeat center;
              }
        }
        .wordpress-bg {
            background: url('../../image-theme-three/wordpress.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/wordpress-hover.svg') no-repeat center;
              }
        }
        .opencart-bg {
            background: url('../../image-theme-three/opencart.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-three/opencart-hover.svg') no-repeat center;
              }
        }
    }

    .back-end-block__main .back-blue-topCross path, .back-end-block__main .back-blue-bottomCross path {
    fill: $azure;
    }

    .back-end-block {
    background: url('../../image-theme-three/cms-line.svg') 100% 68% no-repeat,
                url('../../image-theme-three/cms-line.svg') 0% 68% no-repeat;
    background-size: 42%;
    @media(max-width: 1200px) {
        background: none;
      }
    }

    .back-end-block__subtitle {
    color: $azure;
    }

    .cases-block {
    background: $red-dark;
    }

    .card {
        .opencart-bg {
            .stop-color1 {
                stop-color: $azure;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }
    .card {
        .vue-bg {
            .stop-color1 {
                stop-color: $azure;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }

    .portfolio {
        &__block {
            background: url('../../image-theme-three/table.svg') 104% 100% no-repeat;
            @media(max-width: 768px) {
                background: none;
            }
        }
    }

    .portfolioBlue-blur-circle {
        background: $azure;
    }

    .red-blur-portfolio, .red-blur-portfolioSmall {
        background: $red-dark;
    }

    .top-crossEmpty path, .top-crossFill path, .bottom-crossEmpty path {
        fill: $red-dark;
    }

    .card .card-link {
        color: $red-dark;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $red-dark;
        }
    }

    .descr-link {
        color: $red-dark;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $red-dark;
        }
    }

    .footer {
        background: $azure;
    }

    .footer__main__header span {
        color: $red-dark;
    }

    .transparentEllipse {
        background: url('../../image-theme-three/mail.svg') no-repeat center;
        &:focus-visible, &:hover {
            background: url('../../image-theme-three/mail-icon.svg') no-repeat center;
          }
    }

    .footer__main__header a:hover {
        color: $red-dark;
    }

    .footer-be circle {
        fill: $red-dark;
    }

    .footer-insta .insta-icon {
        fill: $pink;
    }

    .footer-insta path {
        fill: $red-dark;
    }

    .redStar:hover .insta-icon, .footer-be:hover path {
        fill: $azure;
      }
}

html[data-theme="theme-four"] {
    .header{
        transition: background .3s ease-in-out;
        &__block{
          background:
                  url('../../image-theme-four/table-l.svg') 0 0 no-repeat,
                  url('../../image-theme-four/table-r.svg') 100% 100% no-repeat;
                  @media(max-width: 576px) {
                    background: none;
                  }
        }
    }

    .scroll-to-top {
        background: url('../../image-theme-four/scroll-to-top.svg') no-repeat;
        transition: background .3s ease-in-out;
        &:focus-visible, &:hover {
            background: url('../../image-theme-four/scroll-to-top-hover.svg') no-repeat;
        }
    }

    .setting-site-icon path {
        transition: stroke .3s ease-in-out, fill .3s ease-in-out;
    }

    .setting-site-icon path {
        stroke: $orange;
    }

    .setting-site-drop {
        fill: $orange;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path,
    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        transition: fill .3s ease-in-out;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path {
        fill: $orange;
    }

    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        fill: $azure-light;
    }

    .star-elips-head .star-stop1, .star-elips-head .star-stop2 {
        transition: stop-color .3s ease-in-out;
    }

    .star-elips-head {
        .star-stop1 {
            stop-color: $azure-light;
        }
        .star-stop2 {
            stop-color: $black;
        }
    }

    .telegram-link {
        background-image: url('../../image-theme-four/telegram.svg');
        &:hover {
            background-image: url('../../image-theme-four/telegram-hover-theme4.svg');
        }
    }

    .button-red{
        border-color: $orange;
        color: $orange;
        &:hover, &:focus-visible{
          background: $orange;
          color: $black;
        }
      }

    .phone-link {
        color: $orange;
        &:hover, &:focus-visible {
            color: $black;
            background-color: $orange;
        }
    }

    .modal__button, .modal__button-cancel {
        border-color: $orange;
        color: $orange;
        &:disabled {
            border-color: $dark-gray;
            color: $dark-gray;
            &:hover {
                border-color: $dark-gray;
                color: $dark-gray;
                background-color: $black;
            }
        }
    }

    .modal__button:hover, .modal__button-cancel:hover {
        color: $black;
        background-color: $orange;
    }

    .red-blur-circle {
        background: $azure-light;
    }

    .heading-tech {
        background: $azure-light;
    }

    .left-red-blur .blur-for-heading {
        background: $orange;
    }

    .front-end-block {
        &__main {
            .html-bg {
                background: url('../../image-theme-four/html5.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-four/html-hover.svg') no-repeat center;
                  }
            }
        
            .css-bg {
                background: url('../../image-theme-four/css3.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-four/css-hover.svg') no-repeat center;
                  }
            }

            .js-bg {
                background: url('../../image-theme-four/js.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-four/js-hover.svg') no-repeat center;
                  }
            }

            .scss-bg {
                background: url('../../image-theme-four/sass.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-four/sass-hover.svg') no-repeat center;
                  }
            }

            .react-bg {
                background: url('../../image-theme-four/react.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-four/react-hover.svg') no-repeat center;
                  }
            }

            .vue-bg {
                background: url('../../image-theme-four/vue.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-four/vue-hover.svg') no-repeat center;
                  }
            }
        }
    }

    .front-end-block__main .frontend-blue-topCross path,
    .front-end-block__main .frontend-blue-bottomCross path {
        fill: $azure-light;
    }

    .right-red-blur .blur-for-heading {
        background: $orange;
    }
    .back-end-block__main {
        .node-bg {
            background: url('../../image-theme-four/node.js.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/node-hover.svg') no-repeat center;
              }
        }
        .express-bg {
            background: url('../../image-theme-four/ex.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/express-hover.svg') no-repeat center;
              }
        }
        .next-bg {
            background: url('../../image-theme-four/next.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/next-hover.svg') no-repeat center;
              }
        }
        .php-bg {
            background: url('../../image-theme-four/php.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/php-hover.svg') no-repeat center;
              }
        }
        .laravel-bg {
            background: url('../../image-theme-four/laravel.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/laravel-hover.svg') no-repeat center;
              }
        }
        .wordpress-bg {
            background: url('../../image-theme-four/wordpress.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/wordpress-hover.svg') no-repeat center;
              }
        }
        .opencart-bg {
            background: url('../../image-theme-four/opencart.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-four/opencart-hover.svg') no-repeat center;
              }
        }
    }

    .back-end-block__main .back-blue-topCross path, .back-end-block__main .back-blue-bottomCross path {
    fill: $azure-light;
    }

    .back-end-block {
    background: url('../../image-theme-four/cms-line.svg') 100% 68% no-repeat,
                url('../../image-theme-four/cms-line.svg') 0% 68% no-repeat;
    background-size: 42%;
    @media(max-width: 1200px) {
        background: none;
      }
    }

    .back-end-block__subtitle {
    color: $azure-light;
    }

    .cases-block {
    background: $orange;
    }

    .card {
        .opencart-bg {
            .stop-color1 {
                stop-color: $azure-light;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }
    .card {
        .vue-bg {
            .stop-color1 {
                stop-color: $azure-light;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }

    .portfolio {
        &__block {
            background: url('../../image-theme-four/table.svg') 104% 100% no-repeat;
            @media(max-width: 768px) {
                background: none;
            }
        }
    }

    .portfolioBlue-blur-circle {
        background: $azure-light;
    }

    .red-blur-portfolio, .red-blur-portfolioSmall {
        background: $orange;
    }

    .top-crossEmpty path, .top-crossFill path, .bottom-crossEmpty path {
        fill: $orange;
    }

    .card .card-link {
        color: $orange;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $orange;
        }
    }

    .descr-link {
        color: $orange;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $orange;
        }
    }

    .footer {
        background: $azure-light;
    }

    .footer__main__header span {
        color: $orange;
    }

    .transparentEllipse {
        background: url('../../image-theme-four/mail.svg') no-repeat center;
        &:focus-visible, &:hover {
            background: url('../../image-theme-four/mail-icon.svg') no-repeat center;
          }
    }

    .footer__main__header a:hover {
        color: $orange;
    }

    .footer-be circle {
        fill: $orange;
    }

    .footer-insta .insta-icon {
        fill: $pink;
    }

    .footer-insta path {
        fill: $orange;
    }

    .redStar:hover .insta-icon, .footer-be:hover path {
        fill: $azure-light;
      }
}

html[data-theme="theme-five"] {
    .header{
        transition: background .3s ease-in-out;
        &__block{
          background:
                  url('../../image/table-l.svg') 0 0 no-repeat,
                  url('../../image/table-r.svg') 100% 100% no-repeat;
                  @media(max-width: 576px) {
                    background: none;
                  }
        }
    }

    .scroll-to-top {
        background: url('../../image-theme-five/scroll-to-top.svg') no-repeat;
        transition: background .3s ease-in-out;
        &:focus-visible, &:hover {
            background: url('../../image-theme-five/scroll-to-top-hover.svg') no-repeat;
        }
    }

    .setting-site-icon path {
        transition: stroke .3s ease-in-out, fill .3s ease-in-out;
    }

    .setting-site-icon path {
        stroke: $acid;
    }

    .setting-site-drop {
        fill: $acid;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path,
    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        transition: fill .3s ease-in-out;
    }

    .header__red-emptyCross path, 
    .header__red-fillCross path {
        fill: $acid;
    }

    .header__blue-emptyCross path, 
    .header__blue-fillCross path, 
    .header__blue-bottomEmptyCross path {
        fill: $blue;
    }

    .star-elips-head .star-stop1, .star-elips-head .star-stop2 {
        transition: stop-color .3s ease-in-out;
    }

    .star-elips-head {
        .star-stop1 {
            stop-color: $blue;
        }
        .star-stop2 {
            stop-color: $black;
        }
    }

    .telegram-link {
        background-image: url('../../image-theme-five/telegram.svg');
        &:hover {
            background-image: url('../../image-theme-five/telegram-hover-theme5.svg');
        }
    }

    .button-red{
        border-color: $acid;
        color: $acid;
        &:hover, &:focus-visible{
          background: $acid;
          color: $black;
        }
      }

    .phone-link {
        color: $acid;
        &:hover, &:focus-visible {
            color: $black;
            background-color: $acid;
        }
    }

    .modal__button, .modal__button-cancel {
        border-color: $acid;
        color: $acid;
        &:disabled {
            border-color: $dark-gray;
            color: $dark-gray;
            &:hover {
                border-color: $dark-gray;
                color: $dark-gray;
                background-color: $black;
            }
        }
    }

    .modal__button:hover, .modal__button-cancel:hover {
        color: $black;
        background-color: $acid;
    }

    .red-blur-circle {
        background: $blue;
    }

    .heading-tech {
        background: $blue;
    }

    .left-red-blur .blur-for-heading {
        background: $acid;
    }

    .front-end-block {
        &__main {
            .html-bg {
                background: url('../../image/html5-1.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-five/html-hover.svg') no-repeat center;
                  }
            }
        
            .css-bg {
                background: url('../../image/css.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-five/css-hover.svg') no-repeat center;
                  }
            }

            .js-bg {
                background: url('../../image-theme-five/js.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-five/js-hover.svg') no-repeat center;
                  }
            }

            .scss-bg {
                background: url('../../image-theme-five/sass.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-five/sass-hover.svg') no-repeat center;
                  }
            }

            .react-bg {
                background: url('../../image/react.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-five/react-hover.svg') no-repeat center;
                  }
            }

            .vue-bg {
                background: url('../../image/vue.svg') no-repeat center;
                &:focus-visible, &:hover {
                    background: url('../../image-theme-five/vue-hover.svg') no-repeat center;
                  }
            }
        }
    }

    .front-end-block__main .frontend-blue-topCross path,
    .front-end-block__main .frontend-blue-bottomCross path {
        fill: $blue;
    }

    .right-red-blur .blur-for-heading {
        background: $acid;
    }

    .back-end-block__main {
        .node-bg {
            background: url('../../image/node.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/node-hover.svg') no-repeat center;
              }
        }
        .express-bg {
            background: url('../../image/express.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/express-hover.svg') no-repeat center;
              }
        }
        .next-bg {
            background: url('../../image-theme-five/next.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/next-hover.svg') no-repeat center;
              }
        }
        .php-bg {
            background: url('../../image-theme-five/php.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/php-hover.svg') no-repeat center;
              }
        }
        .laravel-bg {
            background: url('../../image/laravel.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/laravel-hover.svg') no-repeat center;
              }
        }
        .wordpress-bg {
            background: url('../../image/wordpress.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/wordpress-hover.svg') no-repeat center;
              }
        }
        .opencart-bg {
            background: url('../../image/opencart.svg') no-repeat center;
            &:focus-visible, &:hover {
                background: url('../../image-theme-five/opencart-hover.svg') no-repeat center;
              }
        }
    }

    .back-end-block__main .back-blue-topCross path, .back-end-block__main .back-blue-bottomCross path {
        fill: $blue;
    }

    .back-end-block {
        background: url('../../image/cms-line.svg') 100% 68% no-repeat,
                    url('../../image/cms-line.svg') 0% 68% no-repeat;
        background-size: 42%;
        @media(max-width: 1200px) {
            background: none;
          }
    }

    .back-end-block__subtitle {
        color: $blue;
    }

    .cases-block {
        background: $acid;
    }

    .card {
        .opencart-bg {
            .stop-color1 {
                stop-color: $blue;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }
    .card {
        .vue-bg {
            .stop-color1 {
                stop-color: $blue;
            }
            .stop-color2 {
                stop-color: $black;
            }
        }
    }

    .portfolio {
        &__block {
            background: url('../../image/table-portfolio.svg') 104% 100% no-repeat;
            @media(max-width: 768px) {
                background: none;
            }
        }
    }

    .portfolioBlue-blur-circle {
        background: $blue;
    }

    .red-blur-portfolio, .red-blur-portfolioSmall {
        background: $acid;
    }

    .top-crossEmpty path, .top-crossFill path, .bottom-crossEmpty path {
        fill: $acid;
    }

    .card .card-link {
        color: $acid;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $acid;
        }
    }

    .descr-link {
        color: $acid;
        &:hover, &:focus-visible {
            color: $white;
            background-color: $acid;
        }
    }

    .footer {
        background: $blue;
    }

    .footer__main__header span {
        color: $acid;
    }

    .transparentEllipse {
        background: url('../../image-theme-five/mail.svg') no-repeat center;
        &:focus-visible, &:hover {
            background: url('../../image-theme-five/mail-icon.svg') no-repeat center;
          }
    }

    .footer__main__header a:hover {
        color: $acid;
    }

    .footer-be circle {
        fill: $acid;
    }

    .footer-insta .insta-icon {
        fill: $pink;
    }

    .footer-insta path {
        fill: $acid;
    }

    .redStar:hover .insta-icon, .footer-be:hover path {
        fill: $blue;
      }
}
